import * as React from 'react';
import {useEffect, useState} from "react";
import {Navigate, useLocation, useOutletContext} from 'react-router-dom';
import Notification, {NotificationType} from "../../common/Notification";
import {currentApiDomain, getServiceNameFromLocation, HEADER_INIT, REQUEST_INIT} from "../../../util/helperUtil";
import {
    Exception,
    GetBadgeDetailsResponse,
    HOME_PAGE_URL, INTERNAL_SERVER_ERROR_CODE_TWO,
    SessionDetails
} from "../../../module/AuthAdminModule";
import NfcTapView from "../../common/NfcTapView";
import ErrorNotification from "../../common/ErrorNotification";
import ShowStatus from "./ShowStatus";


/*
After user click "Check Badge Status" button, navigated to "CheckBadgeStatus" component.
1. Plugin check if reader exist
   1.1 show reader error
2. Enable scan badge reader popup
3. Collect OTP and call API /GetBadgeDetails
4. Display different response pages or warnings.
 */
const CheckBadgeStatus = () => {
    /****  Constants  *****/
    let readerCheckCount = 1;
    const sessionDetails: SessionDetails = useOutletContext();
    const READER_CHECK_LIMIT = 7;
    const READER_RETRY_IN_MILLISECONDS = 5000;
    const OTP_DEFAULT_LENGTH = 67;
    const GET_BADGE_DETAILS_API = `${currentApiDomain}/GetBadgeDetails?`;

    /****  States   *****/
    const location = useLocation();
    const serviceName = getServiceNameFromLocation(location);

    const [state, setState] = useState<any | null>(null);
    const [errorCode, setErrorCode] = useState<string | null>(null);
    const [notification, setNotification] = useState({
        type: NotificationType.INFO, header: "card_reader_check_header", message: "card_reader_check_description"
    });
    const [cardUid, setCardUid] = useState("");
    const [isBadgeScanned, setIsBadgeScanned] = useState(false);
    const [showNFCTapView, setShowNFCTapView] = useState(false);


    /****  Methods   *****/
    const onDismissModal = () => {
        if (isBadgeScanned) {
            setShowNFCTapView(false);
        }
    }

    // handling /GetBadgeDetails API call
    const handleToken = async (tokenValue: string) => {
        // Parse out "urn:" that is included from Velocity NDEF reads
        if (tokenValue.indexOf("urn:") !== -1) {
            tokenValue = tokenValue.slice(4)
        }
        if (tokenValue.length !== 0 && tokenValue.length === OTP_DEFAULT_LENGTH && !isBadgeScanned) {
            try {
                const response = await fetch(GET_BADGE_DETAILS_API + new URLSearchParams({
                    otp: tokenValue, serviceName: serviceName}), {
                    method: "GET", ...REQUEST_INIT,
                    headers: {...HEADER_INIT}
                });
                setCardUid("");
                setShowNFCTapView(false);
                setIsBadgeScanned(true);
                if (response.status === 200) {
                    const responseMessage: GetBadgeDetailsResponse = await response.json();
                    if (responseMessage.success) {
                        setState(responseMessage);
                    }
                } else if (response.status === 401) {
                    // redirect to authenticate
                    window.location.replace(currentApiDomain + "/Authorize");
                } else {
                    // show exception from backend
                    const res: Exception = await response.json();
                    console.error(GET_BADGE_DETAILS_API + " API call failed", res.message, res);
                    setErrorCode(res.errorId ? res.errorId : INTERNAL_SERVER_ERROR_CODE_TWO);
                }
            } catch (err: any) {
                console.error(err.message);
                setShowNFCTapView(false);
                setIsBadgeScanned(true);
                setErrorCode(INTERNAL_SERVER_ERROR_CODE_TWO)
            }
        }
    }

    // Method to post messages from UI to plugin scripts
    const postMessageToPlugin = (messageObj: any) => {
        window.postMessage({...messageObj, source: "frontend"}, window.location.origin);
    }
    const startScan = () => {
        postMessageToPlugin({type: "startNFCReader"});
    }
    const connectCardReader = () => {
        postMessageToPlugin({type: "checkNFCReader"});
    }

    /****  Hook   *****/
    useEffect(() => {
        console.log("Connecting card reader...")
        connectCardReader();
        let isProcessed = false;
        window.addEventListener("message", (event) => {
            if (event.origin === window.location.origin && event.data?.source === "plugin" && !isProcessed) {
                const message = event.data;
                if (message.type === "checkNFCReader") {
                    if (message.isReaderAvailable) {
                        startScan();
                        setShowNFCTapView(true);
                    } else {
                        if (readerCheckCount < READER_CHECK_LIMIT) {
                            setTimeout(function () {
                                connectCardReader();
                            }, READER_RETRY_IN_MILLISECONDS);
                            readerCheckCount++;
                        } else {
                            setNotification({
                                type: NotificationType.ERROR,
                                header: 'reader_issue_header',
                                message: 'reader_issue_description'
                            });
                            isProcessed = true;
                        }
                    }
                } else if (message.type === "NFCReaderTimeout") {
                    console.log("Check reader request timed out...")
                    setShowNFCTapView(false);
                    setNotification({
                        type: NotificationType.ERROR,
                        header: 'reader_issue_header',
                        message: 'reader_timeout_description'
                    });
                    isProcessed = true;
                } else if (message.type === "readNDEF") {
                        setCardUid(message.otp.slice(5, 19));
                        handleToken(message.otp);
                    isProcessed = true;
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (!sessionDetails?.csrfToken) {
        console.error("Could not find CSRF token, redirecting to home page.");
        return <Navigate to={HOME_PAGE_URL} />;
    }

    return (
        <div style={{ width: "60%", margin: "0 auto"}}>
                {!state && !errorCode && (
                    <div>
                        <div style={{width: "70%", margin: "0 auto"}}>
                            <Notification notificationType={notification.type}
                                          headerI18nKey={notification.header}
                                          messageI18nKey={notification.message} />
                        </div>
                        <NfcTapView visible={showNFCTapView} onDismissModal={onDismissModal}></NfcTapView>
                    </div>
                )}

                {errorCode &&
                    <div style={{width: "70%", margin: "0 auto"}}>
                        <ErrorNotification errorCode={errorCode} data={cardUid}/>
                    </div>
                }

                {state && <ShowStatus data={state} />}
        </div>
    );
}

export default CheckBadgeStatus;